import React, { useState } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import { graphql } from "gatsby"
import SEO from "../components/Seo/seo"
import Input from "@mui/material/Input"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import KeyIcon from "@mui/icons-material/Key"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { IconButton, OutlinedInput } from "@material-ui/core"
function CanvaPages({ data, location }) {
  const {
    title,
    description,
    canva_embed_iframe,
    slug,
    seo,
    get_in_touch,
    isPageRequiresPassword,
    password,
  } = data.strapiCanvaPages
  const [show, setShow] = React.useState(!isPageRequiresPassword)
  const [showPassword, setShowPassword] = React.useState(false)
  const [pass, setPass] = React.useState("")
  const [error, setError] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    pass === password ? setShow(true) : setError(true)
  }
  React.useEffect(() => {
    document.body.style.overflow = "hidden"
    // $('iframe, body .ql-editor') = "0 80px";
    return () => (document.body.style.overflow = "scroll")
  }, [])
  return (
    <Container>
     {seo !== null && <SEO
        title={seo ? seo?.title : ""}
        description={seo ? seo.short_description : ""}
        location={`${location.pathname}`}
        image={seo && seo.image?.localFile && seo.image?.localFile.url}
      />}
      <div className="canva_pages_wrapper">
        <h1
          style={{
            position: "absolute",
            visibility: "hidden",
          }}
        >
          {title}
        </h1>
        <h2
          style={{
            position: "absolute",
            visibility: "hidden",
          }}
        >
          {description}
        </h2>
      </div>
      {show ? (
        <ReactMarkdown
          children={canva_embed_iframe}
          allowDangerousHtml={true}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
        />
      ) : (
        <div className="form-canva-page">
          <form validate onSubmit={handleSubmit} id="form-validation">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              id="password"
              helperText={error && "Incorrect Password"}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              InputLabelProps={{
                style: {
                  color: "#151515",
                  opacity: "0.8",
                  fontFamily: "var(--family)",
                  fontWeight: "500",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      id="show-hide"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="form-submit">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      )}
    </Container>
  )
}

export default CanvaPages

const Container = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  iframe {
    height: 100vh !important;
    max-height: unset !important;
  }
  .form-canva-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    #password-helper-text {
      color: red;
    }
    .form-submit {
      display: flex;
      margin-top: 1rem;
      justify-content: center;
      align-items: center;

      button {
        padding: 7px 15px;
        min-width: 90px;
        border-radius: 6px;
        color: var(--secondaryColor);
        background: var(--thirdColor);
        border: 3px solid #2cde80;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  body {
    .ql-editor {
      padding: 0 !important;
    }
  }
`
export const query = graphql`
  query canvaPages($slug: String) {
    strapiCanvaPages(slug: { eq: $slug }) {
      slug
      canva_embed_iframe
      description
      title
      isPageRequiresPassword
      password
      seo {
        short_description
        title
        image {
          localFile {
            url
          }
        }
      }
    }
  }
`
